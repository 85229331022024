const typography = {
    h1: {
        fontWeight: 500,
        fontSize: 24,
        letterSpacing: "-0.24px",
    },
    h2: {
        fontWeight: 500,
        fontSize: 22,
        letterSpacing: "-0.24px",
    },
    h3: {
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: "-0.06px",
    },
    h4: {
        fontWeight: 500,
        fontSize: 18,
        letterSpacing: "-0.06px",
    },
    h5: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: "-0.05px",
    },
    h6: {
        fontWeight: 500,
        fontSize: 14,
        letterSpacing: "-0.05px",
    },
    subtitle1: {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: "-0.05px",
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: "-0.05px",
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: "-0.05px",
    },
    overline: {
        fontWeight: 500,
    },
};

export default typography;
