import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "@hooks/use_auth";

const AuthGuard = ({ children }) => {
    const { isAuthenticated } = useAuth();

    // Redirect back to dashboard if it was accessed unauthorized
    const url = useLocation().pathname;
    const isDashboard = !!~useLocation().pathname.indexOf("dashboard");
    const redirectUrl = localStorage.getItem("url");

    if (!isAuthenticated) {
        url && isDashboard && localStorage.setItem("url", url);
        return <Navigate to="/login" />;
    } else if (redirectUrl && isAuthenticated) {
        localStorage.removeItem("url");
        return <Navigate to={redirectUrl} />;
    }

    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
};

export default AuthGuard;
