import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "guest",
        path: "/reset-password",
        style: "onboarding",
        layout: "auth",
        title: "Reset Password",
        component: lazy(() => import("./index")),
    },
    {
        exact: true,
        guard: "guest",
        path: "/reset-password-confirmation",
        style: "onboarding",
        layout: "auth",
        title: "Reset Password",
        component: lazy(() => import("./complete_reset_password")),
    },
];

export default routes;
