import { THEMES } from "@src/constants";

const styles = (theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                  boxShadow: "none",
                  backgroundColor: theme.palette.primary.main,
              }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default,
              }
            : {}),
    },
    toolbar: {
        minHeight: "64px",
    },
    langIcon: {
        color: "rgba(255,255,255)",
    },
    langSelect: {
        color: "rgba(255,255,255)",
    },
    logo: {
        maxHeight: "30px",
    },
    icon: {
        fill: "rgba(255,255,255)",
    },
});

export default styles;
