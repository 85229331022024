import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: false,
        guard: "guest",
        path: "/sso-login-success",
        layout: "auth",
        title: "Confirm Login",
        component: lazy(() => import("./index")),
    },
];

export default routes;
