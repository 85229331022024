import React, { useEffect } from "react";
import NProgress from "nprogress";
import { Box, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./index.styles";

const useStyles = makeStyles(styles);

const LoadingScreen = () => {
    const classes = useStyles();

    useEffect(() => {
        NProgress.start();
        NProgress.done();
    }, []);

    return (
        <div className={classes.root}>
            <Box width={400}>
                <LinearProgress />
            </Box>
        </div>
    );
};

export default LoadingScreen;
