const styles = (theme) => ({
    root: {
        width: "100%",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
    },
    container: {
        [theme.breakpoints.up("tablet")]: {
            maxWidth: "700px",
        },
        paddingTop: theme.spacing(12),
    },
    footer: {
        paddingTop: theme.spacing(4),
    },
});

export default styles;
