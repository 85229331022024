import React, { createContext, Suspense, useEffect, useReducer } from "react";
import SplashScreen from "@components/splash_screen";
import get from "lodash.get";
import { callApi, Endpoints, UnprotectedEndpoints } from "@utils/api";
import { setUser } from "@slices/user";
import { dispatchTenant, retrieveTenant } from "@slices/company";
import { useDispatch } from "@store";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { CognitoRefreshToken, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import TAwsConfig from "../awsConfig";
import LoadingScreen from "@components/loading_screen";
import { useSnackbar } from "notistack";
import notificationSlice, { getNotifications } from "@slices/notification";
export const INITIALISE = "INITIALISE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_FAILED_MESSAGE = "Login failed. Please try again later";
export const CHANGE_TEMP_PASSWORD = "CHANGE_TEMP_PASSWORD";

export const LOGOUT = "LOGOUT";

export const CONFIRM_INVITATION_REQUEST = "VALIDATE_INVITATION_REQUEST";
export const CONFIRM_INVITATION_SUCCESS = "VALIDATE_INVITATION_SUCCESS";
export const CONFIRM_INVITATION_FAILED = "VALIDATE_INVITATION_FAILED";
export const CONFIRM_INVITATION_MESSAGE = "Validation failed. Please try again later";

export const CONFIRM_INVITATION = "CONFIRM_INVITATION";
export const CLEAR_INVITATION = "CLEAR_INVITATION";

const cookieUrl = `${TAwsConfig.auth.ONEX_AUTH_URL}/cookie`;

const initialAuthState = {
    isInitialised: false,
    isLoggingIn: false,
    isConfirmingInvitation: false,
    invitationToken: null,
    isAuthenticated: false,
    authenticatedTenantId: null,
    authenticatedUserId: null,
    customerName: null,
    customerNickname: null,
    customerLogo: null,
    needsPermanentPass: false,
    user: {},
    invitationCode: null,
};

const parseAuthUser = async (authUser) => {
    const userId = _.get(authUser, "attributes.custom:profile_id", _.get(authUser, "challengeParam.userAttributes.custom:profile_id", ""));
    const userSession = authUser.getSignInUserSession();
    const currentIdToken = userSession.getIdToken();
    const tenantId = _.get(currentIdToken, "payload.tenant_id");

    const { data, error } = await callApi(Endpoints.GetUser(userId), {});

    if (_.get(error, "status") === 401) {
        return { error };
    }

    if (error) {
        throw new Error("Cannot obtain user information. Please try again later.");
    }

    const firstName = _.get(data, "user.firstName", "");
    const lastName = _.get(data, "user.lastName", "");
    const salutation = _.get(data, "user.salutation", "");
    const language = _.get(data, "user.language", "en");
    const email = _.get(data, "user.email", "");
    const role = _.get(data, "user.role", "");

    return {
        tenantId,
        firstName,
        lastName,
        email,
        salutation,
        id: userId,
        language,
        role,
    };
};

const setSessionCookies = async (authUser) => {
    const username = _.get(authUser, "username", "");

    const refreshToken = authUser.getSignInUserSession().getRefreshToken().getToken();

    await fetch(cookieUrl, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            uname: username,
            rt: refreshToken,
        }),
    });
};

const refreshSessionFromCookies = () =>
    fetch(cookieUrl, {
        method: "GET",
        credentials: "include",
    })
        .then((result) => result.json())
        .then(
            (cookies) =>
                new Promise((resolve, reject) => {
                    if (!cookies || !cookies.uname) {
                        reject("no cookies found");
                    }
                    const userPool = new CognitoUserPool({
                        UserPoolId: TAwsConfig.cognito.USER_POOL_ID,
                        ClientId: TAwsConfig.cognito.APP_CLIENT_ID,
                    });
                    const cognitoUser = new CognitoUser({
                        Username: cookies.uname,
                        Pool: userPool,
                    });
                    cognitoUser.refreshSession(new CognitoRefreshToken({ RefreshToken: cookies.rt }), async (err, session) => {
                        if (err) {
                            reject(err.code);
                        } else {
                            resolve();
                        }
                    });
                })
        );

const clearSessionCookies = async () => {
    await fetch(cookieUrl, {
        method: "DELETE",
        credentials: "include",
    });
};

const reducer = (state, action) => {
    switch (action.type) {
        case INITIALISE: {
            const { isAuthenticated, user } = action.payload;
            const { tenantId, id } = user;
            return {
                ...state,
                isAuthenticated,
                authenticatedTenantId: tenantId,
                authenticatedUserId: id,
                isInitialised: true,
            };
        }

        case CONFIRM_INVITATION: {
            const invitationCode = get(action, "payload.invitationCode");
            // Needed for refresh page
            localStorage.setItem("invitationCode", invitationCode);
            return { ...state, invitationCode };
        }

        case CLEAR_INVITATION: {
            localStorage.removeItem("invitationCode");
            return {
                ...state,
                invitationCode: null,
            };
        }

        case LOGIN_REQUEST: {
            return { ...state, isLoggingIn: true };
        }

        case LOGIN_SUCCESS: {
            const { tenantId, id } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                isLoggingIn: false,
                authenticatedTenantId: tenantId,
                authenticatedUserId: id,
                needsPermanentPass: false,
            };
        }

        case LOGIN_FAILED: {
            return {
                ...state,
                isLoggingIn: false,
                isAuthenticated: false,
                authenticatedTenantId: null,
                authenticatedUserId: null,
            };
        }

        case LOGOUT: {
            return {
                ...state,
                isAuthenticated: false,
                authenticatedTenantId: null,
                authenticatedUserId: null,
                user: null,
            };
        }
        case CHANGE_TEMP_PASSWORD: {
            const { user } = action.payload;

            return {
                ...state,
                isAuthenticated: false,
                isLoggingIn: true,
                needsPermanentPass: true,
                user: user,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: "JWT",
    login: () => Promise.resolve(),
    impersonate: () => Promise.resolve(),
    federatedLogin: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    confirmInvitation: () => Promise.resolve(),
    register: () => Promise.resolve(),
    changeTemporaryPassword: () => Promise.resolve(),
    changePassword: () => Promise.resolve(),
    forgotPassword: () => Promise.resolve(),
    forgotPasswordSubmit: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const { enqueueSnackbar } = useSnackbar();

    const storeDispatch = useDispatch();

    const logoutUnauthorized = async () => {
        dispatch({
            type: LOGIN_FAILED,
            payload: { error: "Access authorized only for Administrators!" },
        });
        await logout();
        enqueueSnackbar("Access authorized only for vendors", {
            variant: "error",
        });
        throw new Error("Access authorized only for vendors");
    };

    const changeTemporaryPassword = async (params) => {
        const user = await Auth.completeNewPassword(params.user, params.newPassword);

        if (user) {
            await setSessionCookies(user);
            const currentUser = await parseAuthUser(user);
            if (_.get(currentUser, "error.status") === 401) {
                return await logoutUnauthorized();
            }
            await storeDispatch(setUser(currentUser));
            const { data, error } = await retrieveTenant(currentUser.tenantId);
            if (_.get(error, "status") === 401) {
                return await logoutUnauthorized();
            } else {
                await storeDispatch(dispatchTenant(data));
                await dispatch({ type: LOGIN_SUCCESS, payload: currentUser });
                await storeDispatch(getNotifications());
                return { user };
            }
        }

        dispatch({
            type: LOGIN_FAILED,
            payload: { error: LOGIN_FAILED_MESSAGE },
        });

        return { error: LOGIN_FAILED_MESSAGE };
    };

    const changePassword = async (values) => {
        await Auth.currentAuthenticatedUser().then((user) => {
            return Auth.changePassword(user, values.oldPassword, values.newPassword);
        });

        return logout();
    };

    const forgotPassword = async (username) => {
        return Auth.forgotPassword(username, { from: TAwsConfig.emails.FROM_XFAKTOR });
    };

    const forgotPasswordSubmit = async (username, code, password) => {
        return Auth.forgotPasswordSubmit(username, code, password);
    };

    const login = async (params) => {
        const user = await Auth.signIn(params.username, params.password);
        if (_.get(user, "challengeName") === "NEW_PASSWORD_REQUIRED") {
            await dispatch({
                type: CHANGE_TEMP_PASSWORD,
                payload: { user },
            });
            return user;
        }
        if (user) {
            await setSessionCookies(user);
            const currentUser = await parseAuthUser(user);
            if (_.get(currentUser, "error.status") === 401) {
                return await logoutUnauthorized();
            }
            await storeDispatch(setUser(currentUser));
            const { data, error } = await retrieveTenant(currentUser.tenantId);
            if (_.get(error, "status") === 401) {
                return await logoutUnauthorized();
            } else {
                await storeDispatch(dispatchTenant(data));
                await dispatch({ type: LOGIN_SUCCESS, payload: currentUser });
                await storeDispatch(getNotifications());
                return { user };
            }
        }

        dispatch({
            type: LOGIN_FAILED,
            payload: { error: LOGIN_FAILED_MESSAGE },
        });
        return { error: LOGIN_FAILED_MESSAGE };
    };

    const impersonate = async (email, token) => {
        const userPool = new CognitoUserPool({
            UserPoolId: TAwsConfig.cognito.USER_POOL_ID,
            ClientId: TAwsConfig.cognito.APP_CLIENT_ID,
            endpoint: TAwsConfig.auth.ONEX_AUTH_URL + "/proxy",
        });

        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        return cognitoUser.refreshSession(new CognitoRefreshToken({ RefreshToken: token }), async (err, session) => {
            if (err) {
                enqueueSnackbar("FAILED! Please try again!", {
                    variant: "error",
                });
                console.log("=========== error refreshing session", err, session);
            } else {
                const user = await Auth.currentAuthenticatedUser();
                if (user) {
                    await setSessionCookies(user);
                    const currentUser = await parseAuthUser(user);
                    if (_.get(currentUser, "error.status") === 401) {
                        return await logoutUnauthorized();
                    }
                    window.location.href = "/dashboard";
                }
            }
        });
    };

    const federatedLogin = async (providerName) => {
        dispatch({ type: LOGIN_REQUEST });
        await Auth.federatedSignIn({ customProvider: providerName });
    };

    const logout = async () => {
        await clearSessionCookies();
        await Auth.signOut({ global: true });
        storeDispatch(notificationSlice.actions.clearNotifications());
        dispatch({ type: LOGOUT });
    };

    const confirmInvitation = async (params = {}) => {
        const { invitationCode } = params;

        const { data, error } = await callApi(UnprotectedEndpoints.CheckInvitationCode(invitationCode), {}, "get", {}, false);

        if (data) {
            dispatch({
                type: CONFIRM_INVITATION,
                payload: { invitationCode },
            });
            return { data };
        } else {
            dispatch({
                type: CLEAR_INVITATION,
            });
        }

        return { error: error };
    };

    const register = async (params = {}) => {
        const { data, error } = await callApi(UnprotectedEndpoints.Register, params, "post", {}, false);

        if (data) {
            dispatch({
                type: CLEAR_INVITATION,
            });
            return { data };
        }

        return { error: error };
    };

    const attemptAuthenticateUser = async () => {
        const user = await Auth.currentAuthenticatedUser();

        if (!user) {
            throw new Error("User needs to login");
        }

        const parsedUser = await parseAuthUser(user);

        if (_.get(parsedUser, "error.status") === 401) {
            return await logoutUnauthorized();
        }
        const { id, tenantId } = parsedUser;

        await setSessionCookies(user);
        await storeDispatch(setUser(parsedUser));
        const { data, error } = await retrieveTenant(tenantId);
        if (_.get(error, "status") === 401) {
            return await logoutUnauthorized();
        } else {
            await storeDispatch(dispatchTenant(data));
            dispatch({
                type: INITIALISE,
                payload: {
                    isAuthenticated: true,
                    user: parsedUser,
                },
            });
            await storeDispatch(getNotifications());

            // lang && await i18n.changeLanguage(lang);
            return { id, tenantId };
        }
    };

    useEffect(() => {
        const initialise = async () => {
            const invitationCode = localStorage.getItem("invitationCode");

            if (invitationCode) {
                await confirmInvitation({ invitationCode });
            }

            refreshSessionFromCookies()
                .then(attemptAuthenticateUser)
                .then((payload) => dispatch({ type: LOGIN_SUCCESS, payload }))
                .catch(async (err) => {
                    await storeDispatch(setUser(null));
                    dispatch({
                        type: INITIALISE,
                        payload: { user: {}, isAuthenticated: false },
                    });
                });
        };

        initialise();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "JWT",
                login,
                impersonate,
                federatedLogin,
                logout,
                confirmInvitation,
                register,
                changeTemporaryPassword,
                changePassword,
                forgotPassword,
                forgotPasswordSubmit,
            }}
        >
            <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
        </AuthContext.Provider>
    );
};

export default AuthContext;
