const styles = (theme) => ({
    avatar: {
        height: 20,
        width: 20,
        marginRight: theme.spacing(2),
    },
    name: {
        padding: 0,
    },
    popover: {
        width: 200,
    },
});

export default styles;
