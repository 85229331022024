import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: "/dashboard/tenants",
        layout: "dashboard",
        title: "Companies",
        component: lazy(() => import("./index")),
    },
];

export default routes;
