import React, { Fragment, Suspense, useEffect } from "react";
import { matchPath, Navigate, Route, Routes, useLocation } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard_layout";
import HomeLayout from "./layouts/home_layout";
import AuthLayout from "./layouts/auth_layout";
import LoadingScreen from "@components/loading_screen";
import AuthGuard from "@components/auth_guard";
import GuestGuard from "@components/guest_guard";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const pages = [
    require("@pages/auth/login/index.routes"),
    require("@pages/auth/logout/index.routes"),
    require("@pages/auth/callback/index.routes"),
    require("@pages/auth/reset_password/index.routes"),
    require("@pages/auth/register/index.routes"),
    require("@pages/auth/confirm_registration/index.routes"),
    require("@pages/accept_invitation/index.routes"),
    require("@pages/reject_invitation/index.routes"),
    require("@pages/dashboard/support_users/list/index.routes"),
    require("@pages/dashboard/support_users/edit/index.routes"),
    require("@pages/dashboard/account/user_edit/index.routes"),
    require("@pages/dashboard/notification/index.routes"),
    require("@pages/dashboard/overview/index.routes"),
    require("@pages/dashboard/companies/invitations/list/index.routes"),
    require("@pages/dashboard/companies/users/list/index.routes"),
    require("@pages/dashboard/companies/users/edit/index.routes"),
    require("@pages/dashboard/companies/tenants/list/index.routes"),
    require("@pages/dashboard/companies/tenants/edit/index.routes"),
    require("@pages/dashboard/companies/tenants/create/index.routes"),
    require("@pages/dashboard/system/sync_errors/list/index.routes"),
    require("@pages/dashboard/system/sync_errors/view/index.routes"),
    require("@pages/dashboard/system/migrations/core_tenants_view/index.routes"),
    require("@pages/dashboard/system/migrations/core_tenants_list/index.routes"),
    require("@pages/dashboard/system/migrations/migration_view/index.routes"),
    require("@pages/404/index.routes"),
];

const layouts = {
    home: HomeLayout,
    auth: AuthLayout,
    dashboard: DashboardLayout,
};

const guards = {
    auth: AuthGuard,
    guest: GuestGuard,
};

export const routes = pages.reduce((acc, route) => {
    acc = route.default ? _.concat(acc, route.default) : acc;
    return acc;
}, []);

const RouteComponent = ({ title, ...props }) => {
    const MainComp = props.component;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <MainComp {...props} />
        </>
    );
};

export const RenderRoutes = ({ routes = [] }) => {
    const { t } = useTranslation();

    const url = useLocation();
    const matchRoute = routes.find((route) => {
        return matchPath(route.path, url.pathname);
    });

    const routeGuard = _.get(matchRoute, "guard");
    const Guard = _.get(guards, routeGuard, Fragment);

    const matchLayout = _.get(matchRoute, "layout");
    const Layout = _.get(layouts, matchLayout, Fragment);

    return (
        <Guard>
            <Layout>
                <Suspense fallback={<LoadingScreen />}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        {routes.map((route, i) => {
                            const component = _.get(route, "component");
                            const exact = _.get(route, "exact");
                            const path = _.get(route, "path");
                            const title = _.get(route, "title");
                            const translatedTitle = t(title);
                            const baseTitle = `${translatedTitle ? translatedTitle + " - " : ""}Support Semigator`;

                            return <Route key={i} path={path} exact={exact} element={<RouteComponent title={baseTitle} component={component} />} />;
                        })}
                        <Route path="/404" element={<Navigate to="/404" />} />
                    </Routes>
                </Suspense>
            </Layout>
        </Guard>
    );
};

export default routes;
