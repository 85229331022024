import { create } from "apisauce";
import get from "lodash/get";
import { keysToSnake, keysToCamel } from "@utils/utils";
import TAwsConfig from "../awsConfig";
import { Auth } from "aws-amplify";
import config from "../config";

// TODO: use Amplify.API instead of apisauce here

const Api = create({
    baseURL: TAwsConfig.apiGateway.URL,
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

const UNEXPECTED_ERROR_MESSAGE = "An unexpected error occurred.";

export const Endpoints = {
    ResendConfirmationEmail: `/auth/resend-confirmation`,
    ConfirmRegistration: (token) => `/auth/confirm-registration/${token}`,
    ConfirmInvitation: (token) => `/auth/confirm-invitation/${token}`,
    Login: `/auth/login`,
    Logout: `/auth/logout`,
    ValidateToken: `/auth/forgot_password`,
    RequestResetPassword: "/auth/forgot_password",
    ConfirmResetPasswordRequest: "/auth/forgot_password/",
    ResetPassword: "/auth/forgot_password",
    CheckEmail: "/auth/email_availability",
    CheckIdp: "/auth/idp",
    GetUser: (id) => `/users/${id}`,
    SendTempPassword: (id) => `/users/${id}/sendTempPassword`,
    GetUsers: `/users`,
    GetClients: `/clients`,
    GetCoreTenants: `/semcore-vendors`,
    GetCoreTenant: (id) => `/semcore-vendors/${id}`,
    GetCoreUsers: (id) => `/semcore-vendors/${id}/users`,
    GetMigrations: `/migrations`,
    GetMigration: (id) => `/migrations/${id}`,
    UpdateUser: (id) => `/users/${id}`,
    GetTenant: (id) => `/tenants/${id}`,
    GetTenants: `/tenants`,
    UpdateTenant: (id) => `/tenants/${id}`,
    GetBooking: (id) => `/bookings/${id}`,
    UpdateBooking: (id) => `/bookings/${id}`,
    GetBookings: `/bookings`,
    GetEnquiry: (id) => `/enquiries/${id}`,
    UpdateEnquiry: (id) => `/enquiries/${id}`,
    GetInvitations: `/invitations`,
    GetInvitation: (id) => `/invitations/${id}`,
    GetEnquiries: `/enquiries`,
    GetNotifications: `/notifications`,
    PatchNotification: (id) => `/notifications/${id}`,
    DeleteNotification: (id) => `/notifications/${id}`,
    GetProductRuleGroups: `/rule_groups`,
    ProductGroups: `/product_groups`,
    GetSyncErrors: `/sync_errors`,
    GetSyncError: (id) => `/sync_errors/${id}`,
    ProductGroup: (id) => `/product_groups/${id}`,
    ProductGroupAssign: (id) => `/product_groups/${id}/assign`,
    Products: `/products`,
    Product: (productId) => `/products/${productId}`,
    ProductItems: (productId) => `/products/${productId}/product_items`,
    ProductItem: (productId, productItemId) => `/products/${productId}/product_items/${productItemId}`,
    DuplicateProductItem: (productId, productItemId) => `/products/${productId}/product_items/${productItemId}/duplicate`,
    GetProduct: (id) => `/products/${id}`,
    DuplicateProduct: (id) => `/products/${id}/duplicate`,
    AcceptInvitation: (id) => `/invitations/accept_invitation/${id}`,
    RejectInvitation: (id) => `/invitations/reject_invitation/${id}`,
    //TODO: need this route from BFF
    S3SignedUrl: config.webapp.COMPANY_PROFILE_LOGO_SIGNED_URL,
    ProductsBulk: "/bulk/products",
    ProductItemsBulk: "/bulk/product_items",
    ProductsBulkStatus: "/bulk/products/status",
    ProductItemsBulkStatus: "/bulk/product_items/status",
    Notification: (id) => `/notifications/${id}`,
    Adverts: "/adverts",
    Advert: (advertId) => `/adverts/${advertId}`,
    GetDashboard: "/dashboard",
};

export const UnprotectedEndpoints = {
    Register: `/auth/register`,
    CheckInvitationCode: (id) => `/invitations/check_invitation/${id}`,
    GetImpersonation: (id) => `/users/impersonations/${id}`,
};

export const callApi = async (endpoint, params, method = "get", config = {}, addAuthorizationToRequest = true, disabledCamelResponse = false) => {
    let tokenConfig = null;
    try {
        if (addAuthorizationToRequest) {
            const getAwsCredentials = await Auth.currentSession();
            const idToken = getAwsCredentials.getIdToken().jwtToken;

            tokenConfig = {
                headers: idToken ? { Authorization: "Bearer " + idToken } : {},
            };
        }
        const options = tokenConfig ? { ...tokenConfig, ...config } : { ...config };

        const { ok, data, headers, status } = await Api[`${method}`](endpoint, keysToSnake(params), options);

        if (ok) {
            return {
                data: disabledCamelResponse ? data : keysToCamel(data),
                headers,
            };
        }

        const errorMsg = get(data, "error.msg", get(data, "message"));
        const errorCode = get(data, "error.code", get(data, "statusCode"));

        if (errorMsg && errorCode) {
            return {
                error: { message: errorMsg, code: errorCode, status: status },
            };
        }

        throw new Error(UNEXPECTED_ERROR_MESSAGE);
    } catch (err) {
        const errorMessage = get(err, "message", UNEXPECTED_ERROR_MESSAGE);
        const errorStatus = get(err, "status", 500);
        return { error: { message: errorMessage, status: errorStatus } };
    }
};
