import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./index.styles";

const useStyles = makeStyles(styles);

const SplashScreen = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box width={400}>
                <LinearProgress />
            </Box>
        </div>
    );
};

export default SplashScreen;
