const styles = (theme) => ({
    appBar: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        backgroundColor: "#ffffff",
    },
    toolbar: {
        paddingRight: 0,
        paddingLeft: 0,
    },
    logo: {
        maxHeight: "38px",
    },
    customLogo: {
        maxHeight: "75px",
        maxWidth: "100%",
    },
    textLink: {
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: "none",
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
    },
    buttonLink: {
        textTransform: "none",
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
    },
    langSelect: {
        fontSize: theme.typography.h4.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
    },
});

export default styles;
