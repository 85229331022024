import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "guest",
        path: "/accept_invitation/:invitationCode",
        layout: "accept_invitation",
        title: "Accept Invitation",
        component: lazy(() => import("./index")),
    },
];

export default routes;
