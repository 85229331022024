/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
    /**
     * @memberof configuration
     * @property {object}webapp - Webapp defaults.
     */
    webapp: {
        MAX_ATTACHMENT_SIZE: 5000000,
        PRODUCTS_API_ID: "products",
        PRODUCTS_API_ENDPOINT: "/products",
        PRODUCT_ITEMS_API_ID: "product_items",
        PRODUCT_ITEMS_API_ENDPOINT: "/product_items",
        PRODUCT_RULES_API_ID: "rules",
        PRODUCT_RULES_API_ENDPOINT: "/rules",
        PRODUCT_RULE_GROUPS_API_ID: "rule_groups",
        PRODUCT_RULE_GROUPS_API_ENDPOINT: "/rule_groups",
        PRODUCT_GROUPS_API_ID: "product_groups",
        PRODUCT_GROUPS_API_ENDPOINT: "/product_groups",
        COMPANY_PROFILE_LOGO_SIGNED_URL: "/signed-url",
    },
    core: {
        BASE_URL: process.env.REACT_APP_CORE_BASE_URL,
    },
};

export default config;
