import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Navbar from "./nav_bar";
import useAuth from "@hooks/use_auth";
import TConfig from "../../config";
import styles from "./index.styles";

const corePath = TConfig.core.BASE_URL;
const useStyles = makeStyles(styles);

const HomeLayout = ({ children }) => {
    const classes = useStyles();
    const auth = useAuth();
    const { customerLogo } = auth;
    const logo = customerLogo ? corePath + "/attachments/image/" + customerLogo : null;

    return (
        <div className={classes.root}>
            <Navbar customerLogo={logo} />
            {children}
        </div>
    );
};

HomeLayout.propTypes = {
    children: PropTypes.node,
};

export default HomeLayout;
