const styles = (theme) => ({
    item: {
        display: "block",
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeafActive: {
        background: theme.palette.background.default,
    },
    button: {
        color: "#000000",
        padding: "11px 10px",
        justifyContent: "flex-start",
        borderRadius: 4,
        textTransform: "none",
        lineHeight: "20px",
        letterSpacing: 0,
        width: "100%",
        fontSize: 14,
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
            fontWeight: theme.typography.fontWeightRegular,
        },
        "& :hover": {
            background: theme.palette.background.default,
        },
    },
    subButton: {
        paddingLeft: theme.spacing(2),
    },
    buttonLeaf: {
        color: "#000000",
    },
    icon: {
        display: "flex",
        alignItems: "center",
        marginRight: 14,
    },
    title: {
        marginRight: "auto",
    },
    activeBtn: {
        color: theme.palette.primary.main,
        background: theme.palette.background.default,
        "& :hover": {
            background: theme.palette.background.default,
        },
    },
});

export default styles;
