import React from "react";
import { Link } from "react-router-dom";
import { Select, MenuItem, AppBar, Input, InputAdornment, Toolbar, Grid, Container, useScrollTrigger, useMediaQuery } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import Logo from "@assets/img/logo.svg";
import styles from "./nav_bar.styles";
import Button from "@components/button";

const useStyles = makeStyles(styles);

const ElevationScroll = (props) => {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
};

const LanguageInput = withStyles({
    langIcon: {
        color: "rgba(0, 0, 0, 0.6)",
    },
})((props) => {
    const { classes, ...rest } = props;
    return (
        <Input
            {...rest}
            startAdornment={
                <InputAdornment position="start">
                    <LanguageIcon className={classes.langIcon} />
                </InputAdornment>
            }
        />
    );
});

const Navbar = (props) => {
    const { customerLogo } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const currentLng = i18n.language;

    const onLanguageChange = (e) => {
        const value = e.target.value;
        i18n.changeLanguage(value);
    };

    const ShowRegisterLoginButton = useMediaQuery("(min-width:800px)");

    return (
        <ElevationScroll>
            <AppBar className={classes.appBar} position="sticky">
                <Toolbar className={classes.toolbar}>
                    <Container>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Link to="/">
                                    <img
                                        onClick={window.scrollTo({
                                            top: 0,
                                            behavior: "smooth",
                                        })}
                                        src={customerLogo || Logo}
                                        alt="Semigator"
                                        className={customerLogo ? classes.customLogo : classes.logo}
                                    />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="flex-end" alignItems="center" spacing={5}>
                                    <Grid item>
                                        <Select
                                            id="language"
                                            disableUnderline
                                            value={currentLng}
                                            input={<LanguageInput />}
                                            className={classes.langSelect}
                                            onChange={onLanguageChange}
                                        >
                                            <MenuItem value="en">{t("english")}</MenuItem>
                                            <MenuItem value="de">{t("german")}</MenuItem>
                                        </Select>
                                    </Grid>
                                    {ShowRegisterLoginButton && (
                                        <Grid item>
                                            <Grid container spacing={5}>
                                                <Grid item>
                                                    <Button
                                                        component={Link}
                                                        color="primary"
                                                        variant="text"
                                                        size="large"
                                                        to="/login"
                                                        className={classes.textLink}
                                                    >
                                                        {t("landing.login")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        component={Link}
                                                        color="primary"
                                                        variant="contained"
                                                        size="large"
                                                        to="/register"
                                                        className={classes.buttonLink}
                                                    >
                                                        {t("landing.register")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    );
};

export default Navbar;
