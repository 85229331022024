const styles = (theme) => ({
    primary: {
        "&:hover": {
            opacity: 0.9,
        },
    },

    primaryOutlined: {
        background: theme.palette.primary.main,
        color: theme.palette.background.white,
        border: `1px solid ${theme.palette.background.white}`,
        "&:hover": {
            opacity: 0.9,
            background: theme.palette.primary.main,
        },
    },

    secondary: {
        background: theme.palette.background.white,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
            opacity: 0.9,
            background: theme.palette.background.white,
            color: theme.palette.primary.main,
        },
    },

    tertiary: {
        background: theme.palette.tertiary.main,
        color: theme.palette.background.white,
        "&:hover": {
            opacity: 0.9,
            background: theme.palette.tertiary.main,
        },
    },

    success: {
        background: theme.palette.success.main,
        "&:hover": {
            opacity: 0.9,
            background: theme.palette.success.main,
        },
    },

    warning: {
        background: theme.palette.warning.main,
        "&:hover": {
            opacity: 0.9,
            background: theme.palette.warning.main,
        },
    },

    error: {
        background: theme.palette.error.main,
        "&:hover": {
            opacity: 0.9,
            background: theme.palette.error.main,
        },
    },
});

export default styles;
