import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./footer.styles";

const useStyles = makeStyles(styles);

const Footer = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const currentYear = new Date().getFullYear();

    const FooterText = ({ text, link }) => {
        const textComponent = (
            <Typography variant="body2" display="inline" className={classes.text}>
                {text}
            </Typography>
        );

        return link ? (
            <Link href={link} target="_blank" color="textPrimary">
                {textComponent}
            </Link>
        ) : (
            textComponent
        );
    };

    return (
        <Grid container direction="column" justifyContent="flex-end">
            <Grid container>
                <Grid item xs={12} className={classes.item}>
                    <FooterText text={t("footer.legalNotice")} link="https://www.semigator.de/impressum" />
                    <FooterText text={t("footer.privacyPolicy")} link="https://www.semigator.de/agb_datenschutz" />
                    <FooterText text={t("footer.terms")} link="https://semigator.de/agbprovider_corporate" />
                </Grid>
                <Grid item xs={12} className={classes.item}>
                    <FooterText text={`Copyright © ${currentYear} Semigator GmbH`} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Footer;
