import { createTheme, colors, responsiveFontSizes } from "@mui/material";
import { softShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
    name: "XFAKTOR",
    direction: "ltr",
    typography: typography,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            tablet: 768,
            md: 960,
            lg: 1280,
            laptop: 1400,
            xl: 1920,
        },
    },
    components: {
        badge: {
            padding: "12px 8px",
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: "#F1F5F9",
                },
            },
        },
    },
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: "hidden",
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: "rgba(0,0,0,0.075)",
            },
        },
        MuiInputBase: {
            input: {
                "&::placeholder": {
                    opacity: 1,
                    color: colors.blueGrey[600],
                },
            },
        },
    },
    palette: {
        type: "light",
        action: {
            active: colors.blueGrey[600],
        },
        background: {
            default: "#F1F5F9",
            main: "#F1F5F9",
            dark: "#F1F5F9",
            white: "#fff",
            paper: colors.common.white,
        },
        dark: {
            main: "#000000",
        },
        lightGray: {
            main: "#EEEEEE",
        },
        greenGray: {
            main: "#546E7A",
        },
        primary: {
            main: "#3949AB",
        },
        secondary: {
            main: "#5850EC",
        },
        tertiary: {
            main: "#0d9de0",
        },
        success: {
            main: "#4CAF50",
            contrastText: "#FFF",
        },
        error: {
            main: "#D62755",
            hover: "#971739",
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
            contrastText: "#FFF",
        },
    },
    shadows: softShadows,
};

export const createAppTheme = (config = {}) => {
    let theme = createTheme(baseOptions);

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
};
