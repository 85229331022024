import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "guest",
        path: "/register",
        layout: "auth",
        title: "register.metaTitle",
        component: lazy(() => import("./index")),
    },
];

export default routes;
