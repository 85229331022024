const styles = (theme) => ({
    root: {
        alignItems: "center",
        backgroundColor: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        minHeight: "100%",
        padding: theme.spacing(3),
    },
});

export default styles;
