import React from "react";
import LogoWhite from "@assets/img/logo_white.svg";
import LogoGrey from "@assets/img/logo.svg";

const Logo = (props) => {
    const { color } = props;

    return <img alt="Logo" src={color === "gray" ? LogoGrey : LogoWhite} style={{ height: 24 }} {...props} />;
};

export default Logo;
