import React from "react";
import { Select, MenuItem, AppBar, Input, InputAdornment, Toolbar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import styles from "./nav_bar.styles";
import clsx from "clsx";
import Logo from "@components/logo";

const useStyles = makeStyles(styles);

const Navbar = (props) => {
    const { className, onMobileNavOpen, ...rest } = props;

    const classes = useStyles();

    const { t, i18n } = useTranslation();

    const currentLng = i18n.language;

    const onLanguageChange = (e) => {
        const value = e.target.value;
        return i18n.changeLanguage(value);
    };

    const LanguageInput = (
        <Input
            startAdornment={
                <InputAdornment position="start">
                    <LanguageIcon className={classes.langIcon} />
                </InputAdornment>
            }
        />
    );

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/">
                    <Logo className={classes.logo} />
                </RouterLink>
                <Box flexGrow={1} />
                <Box>
                    <Select
                        id="language"
                        disableUnderline
                        value={currentLng}
                        input={LanguageInput}
                        className={classes.langSelect}
                        onChange={onLanguageChange}
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                            },
                        }}
                    >
                        <MenuItem value="en">{t("english")}</MenuItem>
                        <MenuItem value="de">{t("german")}</MenuItem>
                    </Select>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
