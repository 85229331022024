import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import TAwsConfig from "@src/awsConfig";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "de",
        fallbackLng: "en",
        defaultNS: "common",
        fallbackNS: "common",
        ns: ["common", "countries"],
        supportedLngs: ["en", "de"],
        backend: {
            loadPath:
                process.env.NODE_ENV === "development" ? `/locales/{{lng}}/{{ns}}.json` : TAwsConfig.locales.URL + "/support/{{lng}}/{{ns}}.json",
            requestOptions: {
                mode: "cors",
            },
        },
        react: {
            useSuspense: true,
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
