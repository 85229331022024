import { createStyles, makeStyles } from "@mui/styles";
import styles from "./index.styles";

const useStyles = makeStyles(() => createStyles(styles));

const GlobalStyles = () => {
    useStyles();
    return null;
};

export default GlobalStyles;
