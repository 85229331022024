// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint-disable no-template-curly-in-string */
const config = {
    domain: "https://app-support.xfaktor-stage-sb.app.semigator.net",
    vendor: {
        domain: "app-vendor.xfaktor-stage-sb.app.semigator.net",
        official_domain: "partner.semigator.net",
    },
    s3: {
        REGION: "eu-central-1",
        BUCKET: "xfaktor-support-stage-sb-upload",
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://3ijk3esf5f.execute-api.eu-central-1.amazonaws.com/stage/v1",
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_vkWdJ4MWN",
        APP_CLIENT_ID: "qh9ups4tnt53cnb5h1u7ic4um",
    },
    locales: {
        URL: "https://locales.xfaktor-stage-sb.app.semigator.net",
    },
    auth: {
        ONEX_AUTH_URL: "https://auth.one-staging-sb.platform-staging.haufe.io/v1",
    },
    semcore: {
        PROXY_URL: "https://xfaktor-stg.semigator.de/xfaktor-vendor-app",
    },
    emails: {
        FROM_XFAKTOR: "Partner Management <partner@semigator.rocks>",
    },
};
/* eslint-enable no-template-curly-in-string */

export default config;
