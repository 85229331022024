const styles = (theme) => ({
    mobileDrawer: {
        width: 250,
        border: "none",
        padding: "0px 16px",
        zIndex: theme.zIndex.drawer + 100,
    },
    contactWrapper: {
        paddingTop: 10,
    },
    subheader: {
        fontSize: 14,
        textTransform: "uppercase",
        lineHeight: "18px",
        paddingBottom: 26,
        letterSpacing: "0.06em",
        fontWeight: theme.typography.fontWeightMedium,
        height: "18px",
        width: "67px",
    },
    desktopDrawer: {
        width: 250,
        top: 64,
        padding: "0px 16px",
        height: "calc(100% - 64px)",
        border: "none",
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
    contactHeader: {
        paddingBottom: 10,
    },
    contactSubWrapper: {
        padding: "15px 20px",
    },
    contactLink: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: 14,
        textTransform: "none",
        textDecoration: "none",
        color: theme.palette.primary.main,
        lineHeight: "15px",
    },
});

export default styles;
