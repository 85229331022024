import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Navbar from "./nav_bar";
import Footer from "./footer";
import styles from "./index.styles";

const useStyles = makeStyles(styles);

const AuthLayout = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container direction="column" alignItems="center">
                <Grid container direction="column" item xs={12} className={classes.container}>
                    <Grid item>
                        <Navbar />
                    </Grid>
                    <Grid item>{children}</Grid>
                    <Grid className={classes.footer} item>
                        <Footer />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.node,
};

export default AuthLayout;
