import _ from "lodash";
import { unparse } from "papaparse";
import moment from "moment/moment";

export const getAttachmentSource = (attachment) => {
    //TODO commented lines are old implementation. To be validated

    // const rawFile = _.get(attachment, "file");
    // const name = _.get(attachment, "name");
    // const extension = /[^.]+$/.exec(name);
    // const id = _.get(attachment, "id");
    return attachment
        ? {
              // id,
              // name,
              // src: `data:image/${extension};base64,${rawFile}`,
              src: _.get(attachment, "src"),
          }
        : attachment;
};

export const getImageDimensions = (file) => {
    return new Promise(function (resolved, rejected) {
        let i = new Image();

        i.onload = function () {
            resolved({ w: i.width, h: i.height });
        };
        i.src = file;
    });
};

export const keysToSnake = (obj) => {
    if (typeof obj !== "object" || obj === null) return obj;

    const newObj = Array.isArray(obj) ? [...obj] : { ...obj };

    for (const oldName in newObj) {
        const newName = oldName.replace(/([A-Z])/g, function ($1) {
            return "_" + $1.toLowerCase();
        });

        if (newName !== oldName) {
            //avoid a ReferenceError in strict mode
            if (newObj.hasOwnProperty(oldName)) {
                newObj[newName] = newObj[oldName];
                delete newObj[oldName];
            }
        }

        if (typeof newObj[newName] == "object") {
            newObj[newName] = keysToSnake(newObj[newName]);
        }
    }

    return newObj;
};

export const filterFormikChangedValues = (values, initialPayload) =>
    Object.entries(values).reduce((acc, [key, value]) => {
        if (typeof value === "object") {
            acc[key] = filterFormikChangedValues(value, initialPayload[key]);
        } else {
            const valueChanged = initialPayload[key] !== value;
            if (valueChanged) {
                acc[key] = value;
            }
        }
        return acc;
    }, {});

const toCamel = (str) => {
    return str.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
    });
};

const isObject = (obj) => {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function";
};

export const keysToCamel = (obj) => {
    if (isObject(obj)) {
        const n = {};

        Object.keys(obj).forEach((k) => {
            n[toCamel(k)] = keysToCamel(obj[k]);
        });

        return n;
    } else if (Array.isArray(obj)) {
        return obj.map((i) => {
            return keysToCamel(i);
        });
    }

    return obj;
};

export const filteredObject = (object) => {
    return _.cloneDeepWith(object, function customizer(v) {
        return _.isObject(v) ? _.cloneDeepWith(_.omitBy(v, _.isUndefined), _.after(2, customizer)) : undefined;
    });
};

export const formikTouchedErrors = (errors, touched) =>
    Object.keys(errors)
        .map((errorKey) => {
            const errorValue = errors[errorKey];

            if (Array.isArray(errorValue)) {
                return errorValue
                    .map((subError) => {
                        return typeof subError === "object"
                            ? Object.keys(subError)
                                  .filter((subKey) => !!_.get(touched, `[${errorKey}][${subKey}]`))
                                  .map((subKey) => subError[subKey])
                                  .join("")
                            : "";
                    })
                    .join("");
            }

            return touched[errorKey] || errorKey === "submit" ? errorValue : "";
        })
        .join("");

export const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve({ src: reader.result, name: file.name });
        reader.onerror = reject;

        reader.readAsDataURL(file);
    });
};

const addBom = (content) => {
    return "\ufeff" + content;
};

export const exportCSV = async (name, csvHeaderMap, enqueueSnackbar) => {
    try {
        const csvContent = addBom(
            unparse(csvHeaderMap, {
                delimiter: ";",
            })
        );

        const tempLink = document.createElement("a");
        const csvFile = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        tempLink.style.display = "none";
        tempLink.href = URL.createObjectURL(csvFile);
        tempLink.download = `export-${name}-list-${moment().format("YYYY-MM-DD")}.csv`;
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);

        enqueueSnackbar(`Generating ${name} export`, {
            variant: "success",
        });
    } catch (err) {
        enqueueSnackbar(`Something went wrong.Cannot export ${name} list.` + err, {
            variant: "error",
        });

        throw err;
    }
};
