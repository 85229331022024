/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Box, Drawer, List, ListSubheader, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PieChart as PieChartIcon, Settings as SettingsIcon } from "react-feather";
import Logo from "@components/logo";
import NavItem from "./nav_item";
import styles from "./index.styles";
import { useTranslation } from "react-i18next";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupIcon from "@mui/icons-material/Group";
import EmailIcon from "@mui/icons-material/Email";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import CloudSyncIcon from "@mui/icons-material/CloudSync";

const Sections = () => {
    const { t } = useTranslation();

    return [
        {
            subheader: "",
            items: [
                {
                    title: t("navigation.dashboard"),
                    icon: PieChartIcon,
                    href: "/dashboard",
                    style: { marginBottom: 5 },
                },
            ],
        },
        {
            subheader: t("Companies"),
            items: [
                {
                    title: t("Invitations"),
                    icon: EmailIcon,
                    href: "/dashboard/invitations",
                    includeSubPaths: true,
                },
                {
                    title: t("Companies List"),
                    href: "/dashboard/tenants",
                    icon: ApartmentIcon,
                    includeSubPaths: true,
                },
                {
                    title: t("Users List"),
                    href: "/dashboard/users",
                    icon: GroupIcon,
                    includeSubPaths: true,
                },
            ],
        },
        {
            subheader: t("System"),
            items: [
                {
                    title: t("Sync Errors"),
                    href: "/dashboard/sync-errors",
                    icon: SyncProblemIcon,
                    includeSubPaths: true,
                },
                {
                    title: t("Migrations"),
                    href: "/dashboard/core-tenants",
                    icon: CloudSyncIcon,
                    includeSubPaths: true,
                },
            ],
        },
        {
            subheader: "ADMINISTRATION",
            items: [
                {
                    title: t("Support Users"),
                    href: "/dashboard/support-users",
                    icon: SettingsIcon,
                    includeSubPaths: true,
                },
            ],
        },
    ];
};

function renderNavItems({ items, pathname, depth = 0 }) {
    return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;
    const match = matchPath(item.href + (item.includeSubPaths ? "/*" : ""), pathname);

    if (item.items) {
        const open = matchPath(item.href, pathname);

        acc.push(
            <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
                active={match}
                style={item.style}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(styles);

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();
    const lgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box sx={{ display: { lg: "none", md: "block" } }} p={2} display="flex" justifyContent="center">
                    <RouterLink to="/">
                        <Logo color={lgDown ? "gray" : "white"} />
                    </RouterLink>
                </Box>
                <Box p={2}>
                    {Sections().map((section) => (
                        <List
                            key={section.subheader}
                            subheader={
                                <ListSubheader disableGutters disableSticky className={classes.subheader}>
                                    {section.subheader}
                                </ListSubheader>
                            }
                        >
                            {renderNavItems({
                                items: section.items,
                                pathname: location.pathname,
                            })}
                        </List>
                    ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <Drawer
            sx={{ zIndex: (theme) => theme.zIndex.drawer + (lgDown ? 2 : 0) }}
            anchor="left"
            classes={{
                paper: lgDown ? classes.mobileDrawer : classes.desktopDrawer,
            }}
            onClose={lgDown ? onMobileClose : undefined}
            open={lgDown ? openMobile : true}
            variant={lgDown ? "temporary" : "permanent"}
        >
            {content}
        </Drawer>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
