import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: "/reject_invitation/:invitationCode",
        layout: "reject_invitation",
        component: lazy(() => import("./index")),
    },
];

export default routes;
