import { createSlice } from "@reduxjs/toolkit";
import { callApi, Endpoints } from "@utils/api";
import _ from "lodash";

const initialState = {
    notifications: [],
    loading: false,
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        startLoading(state) {
            return { ...state, loading: true };
        },
        endLoading(state) {
            return { ...state, loading: false };
        },
        clearNotifications() {
            return initialState;
        },
        saveNotifications(state, action) {
            const notifications = _.get(action, "payload", []);
            return { notifications, loading: false };
        },
        markAllAsRead(state) {
            state.notifications.forEach((notification) => {
                notification.read = true;
            });
            state.loading = false;
            return state;
        },
        markAllAsSeen(state) {
            state.notifications.forEach((notification) => {
                notification.seen = true;
            });
            state.loading = false;
            return state;
        },
        markOneAsRead(state, action) {
            const id = _.get(action, "payload.id");
            const idx = state.notifications.findIndex((n) => n.id === id);
            if (state.notifications && state.notifications[idx]) {
                state.notifications[idx].read = true;
            }
            return state;
        },
        deleteOne(state, action) {
            const { id = "" } = action.payload;
            const others = state.notifications.filter((n) => n.id !== id);
            return {
                notifications: [...others],
            };
        },
    },
});

export const reducer = notificationSlice.reducer;

export const getNotifications = () => async (dispatch) => {
    dispatch(notificationSlice.actions.startLoading());
    const { data, error } = await callApi(Endpoints.GetNotifications, {}, "get");
    if (data) {
        dispatch(notificationSlice.actions.saveNotifications(data));
    }
    return { data, error };
};

export const updateAllAs = (property) => async (dispatch) => {
    dispatch(notificationSlice.actions.startLoading());

    const params = {};
    if (["seen", "read"].includes(property)) {
        params[`${property}`] = true;
        const result = await callApi(Endpoints.GetNotifications, params, "patch");
        if (result?.data) {
            property === "seen" ? dispatch(notificationSlice.actions.markAllAsSeen()) : dispatch(notificationSlice.actions.markAllAsRead());
        }
    }
};

export const deleteAll = () => async (dispatch) => {
    dispatch(notificationSlice.actions.startLoading());
    const { error } = await callApi(Endpoints.GetNotifications, {}, "delete");
    if (!error) {
        dispatch(notificationSlice.actions.clearNotifications());
    }
    return { error };
};

export const deleteOne = (id) => async (dispatch) => {
    const { error } = await callApi(Endpoints.DeleteNotification(id), {}, "delete");
    if (!error) {
        dispatch(notificationSlice.actions.deleteOne({ id }));
    }
    return { error };
};

export const updateOneAsRead = (id) => async (dispatch) => {
    const { error } = await callApi(Endpoints.PatchNotification(id), { read: true }, "patch");
    if (!error) {
        dispatch(notificationSlice.actions.markOneAsRead({ id }));
    }
    return { error };
};

export default notificationSlice;
