import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Collapse, ListItem, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styles from "./nav_item.styles";

const useStyles = makeStyles(styles);

const NavItem = ({ children, className, depth, href, icon: Icon, info: Info, open: openProp, active, title, style, ...rest }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(openProp);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    if (children) {
        return (
            <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
                <Button className={clsx(classes.button, classes.subButton)} onClick={handleToggle}>
                    {Icon && <Icon className={classes.icon} size="20" />}
                    <span className={classes.title}>{title}</span>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    }

    return (
        <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} style={style} {...rest}>
            <Button
                className={clsx(classes.button, active ? classes.activeBtn : clsx(classes.buttonLeaf, `depth-${depth}`))}
                component={NavLink}
                exact="exact"
                fullWidth
                color={active ? "primary" : "dark"}
                to={href}
            >
                {Icon && <Icon className={classes.icon} size="20" />}
                <span className={classes.title}>{title}</span>
                {Info && <Info />}
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
    open: false,
};

export default NavItem;
