import { lazyRetry as lazy } from "@utils/lazyRetry";

const routes = [
    {
        exact: true,
        guard: "auth",
        path: "/dashboard",
        layout: "dashboard",
        title: "Dashboard",
        component: lazy(() => import("./index")),
    },
    {
        guard: "auth",
        path: "/dashboard/*",
        layout: "dashboard",
        title: "Dashboard",
        component: lazy(() => import("./index")),
    },
];

export default routes;
